<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar
      flat
    >
      <v-toolbar-title>Change Rank</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        label="Enter dealer"
        clearable
      />
      <v-spacer />
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container grid-list-xs>
          <v-layout
            row
            wrap
          >
            <v-flex xs6>
              <v-text-field
                v-model="name"
                label="Dealer's name"
                outline
                disabled
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="dealerID"
                label="Dealer ID"
                outline
                disabled
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="rank"
                label="Dealer's rank"
                outline
                disabled
              />
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="dealer"
                :items="dealers"
                label="Select rank"
              />
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
          >
            <v-flex xs2>
              <v-btn
                :disabled="!dealer"
                @click="executeDialog = true"
              >
                Change Rank
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="10000"
      :color="snackbarColor"
      top
    >
      {{ snackbarText }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="executeDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Execute Change rank on {{ dealerID }}</span>
        </v-card-title>

        <v-alert
          :value="true"
          :type="executeAlertType"
          v-html="executeAlertMessage"
        />

        <v-card-actions>
          <v-btn
            :disabled="loading"
            color="blue darken-1"
            flat
            @click="executeDialog = false"
          >
            {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
          </v-btn>
          <v-btn
            v-show="executeAlertType === 'info'"
            :loading="loading"
            :disabled="loading"
            color="blue darken-1"
            flat
            @click="executeChangeRank"
          >
            Execute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </panel>
</template>

<script>
import { createPostParams, createGetParams } from '@/rest'
const formTitle = 'Change Rank'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      breadcrumbs: [
        {
          text: 'Snm', disabled: false, to: '/snm/snm_kits',
        },
        {
          text: 'Change Rank', disabled: true,
        },
      ],
      name: [],
      rank: null,
      msisdn: null,
      dealerID: null,
      search: null,
      dealer: null,
      dealers: [
        {
          text: 'Dealer',
          value: 'Dealer',
        },
        {
          text: 'Area Dealer',
          value: 'Area Dealer',
        },
        {
          text: 'Master Dealer',
          value: 'Master Dealer',
        },
      ],
      items: [],
      loading: false,
      snackbar: false,
      snackbarText: null,
      snackbarColor: 'success',
      target: 0,
      title: formTitle,
      executeDialog: false,
      executeAlertType: 'info',
      executeAlertMessage: null,
    }
  },
  watch: {
    search: function (val) {
      if (val) {
        this.getDealer(val)
      }
    },
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are confirm to change the dealer rank</div>'
      }
    },
  },
  methods: {
    getDealer: function () {
      const params = createGetParams({
        dealer: this.search,
      })
      this.$rest.get('getDealerRank.php', params)
        .then(function (response) {
          this.name = response.data.name
          this.rank = response.data.rank
          this.dealerID = response.data.dealer
          this.msisdn = response.data.msisdn
        }.bind(this)).catch(() => {
          // (error)
        })
    },
    async executeChangeRank () {
      const params = createPostParams({
        action: 'changeRank',
        payload: {
          dealerID: this.dealerID,
          rank: this.dealer,
          msisdn: this.msisdn,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to change rank was successful.'
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
